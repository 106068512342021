import { IonItem, IonSpinner } from '@ionic/react'
import { AdminGraphFooter } from 'pages/portfolio/Info'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts'
import { ArrayElement } from 'safety'
import { RouterOutputs, trpc } from 'utils/trpc'

type GraphPoint = ArrayElement<RouterOutputs['adminDashboardRouter']['getGraphData']>

export default function AdminOverallGraph(props: { selectedFnId: number }) {
  const { isLoading: isAuthLoading } = usePluralAuth()

  const graphDataQuery = trpc.adminDashboardRouter.getGraphData.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const [graphData, setGraphData] = useState<GraphPoint[]>(graphDataQuery.data ?? [])

  const graphDataFunctions: { id: number; fn: (point: GraphPoint, i: number) => GraphPoint }[] = [
    {
      id: 1,
      fn: (point: GraphPoint, i: number) => point,
    },
    {
      id: 2,
      fn: (point: GraphPoint, i: number) => {
        if (i === 0) return point
        return {
          ...point,
          totalCommits: point.totalCommits - graphData[i - 1].totalCommits,
          totalAssets: point.totalAssets - graphData[i - 1].totalAssets,
          totalInvestments: point.totalInvestments - graphData[i - 1].totalInvestments,
          totalCarry: point.totalCarry - graphData[i - 1].totalCarry,
          totalManagementFees: point.totalManagementFees - graphData[i - 1].totalManagementFees,
        }
      },
    },
  ]

  let graphDataFn = graphDataFunctions.find((fn) => fn.id === props.selectedFnId)?.fn

  let trueGraphData = graphDataFn ? graphData.map(graphDataFn) : graphData

  // TODO: remove
  trueGraphData = trueGraphData.filter((e) => new Date(e.date).getTime() < new Date('2024-010-15').getTime())

  useEffect(() => {
    if (graphDataQuery.data) {
      setGraphData(graphDataQuery.data)
    } else {
      setGraphData([])
    }
  }, [graphDataQuery.data])

  function yDomainMin() {
    let min = Math.min(
      ...trueGraphData.flatMap((datum) => [
        datum.totalCommits,
        datum.totalAssets,
        datum.totalInvestments,
        datum.totalCarry,
        datum.totalManagementFees,
      ]),
    )
    return min * 0.99
  }
  function yDomainMax() {
    let max = Math.max(
      ...trueGraphData.flatMap((datum) => [
        datum.totalCommits,
        datum.totalAssets,
        datum.totalInvestments,
        datum.totalCarry,
        datum.totalManagementFees,
      ]),
    )
    return max * 1.01
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log('payload', payload[0].payload.date)

      const formattedCommits = formatTooltipValue(payload[0]?.value)
      const formattedAssets = formatTooltipValue(payload[1]?.value)
      const formattedInvestments = formatTooltipValue(payload[2]?.value)
      const formattedCarry = formatTooltipValue(payload[3]?.value)
      const formattedManagementFees = formatTooltipValue(payload[4]?.value)

      const date = new Date(label)
      let graphPointIdx = trueGraphData.findIndex((point) => point.date === date.toISOString())
      let lastGraphPoint = trueGraphData[graphPointIdx - 1]
      const lastDate = lastGraphPoint ? new Date(lastGraphPoint.date) : undefined

      const formattedLabel =
        props.selectedFnId === 1 || !lastDate
          ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
          : `${lastDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`
      return (
        <div
          className="custom-tooltip flex flex-col items-center rounded-md pb-3 pl-4 pr-4 pt-3 text-white"
          style={{ backgroundColor: '#1C2227' }}>
          <div className="label mb-2 text-sm opacity-70">{formattedLabel}</div>
          <div className="value  text-xl font-semibold">{formattedAssets}</div>
          <div className="mb-2 text-sm opacity-70">{props.selectedFnId === 1 ? 'Total AUM' : 'AUM Change'}</div>
          <div className="value  text-xl font-semibold">{formattedCommits}</div>
          <div className="mb-2 text-sm opacity-70">
            {props.selectedFnId === 1 ? 'Total Commited' : 'Commits Received'}
          </div>
          <div className="value  text-xl font-semibold">{formattedInvestments}</div>
          <div className="opacity-7 mb-2 text-sm">
            {props.selectedFnId === 1 ? 'Total Invested' : 'Investments Entry Value Change'}
          </div>
          <div className="value  text-xl font-semibold">{formattedCarry}</div>
          <div className="opacity-7 mb-2 text-sm">{props.selectedFnId === 1 ? 'Total Carry' : 'Carry generated'}</div>
          <div className="value  text-xl font-semibold">{formattedManagementFees}</div>
          <div className="opacity-7 mb-2 text-sm">
            {props.selectedFnId === 1 ? 'Total Management Fees' : 'Management Fees generated'}
          </div>
        </div>
      )
    }
    return null
  }

  const formatTooltipValue = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  }

  const activeGraphData = graphData[graphData.length - 1]
  if (graphDataQuery.isLoading || isAuthLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <div className="loader flex h-[100vh] w-full flex-col items-center justify-center">
          <IonItem>
            <IonSpinner name="lines-sharp"></IonSpinner>
          </IonItem>
        </div>
      </div>
    )
  }
  if (!activeGraphData) return null
  return (
    <div className="w-full text-left">
      <Legend />
      <div className="w-full">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={trueGraphData} margin={{ top: 2, right: 22, left: 16, bottom: 4 }}>
            <XAxis
              dataKey="date"
              tick={true}
              tickLine={false}
              height={32}
              axisLine={false}
              tickFormatter={(value) => {
                console.log('val', value)
                return new Date(value).toLocaleDateString('en-US', { year: '2-digit', month: 'short' })
              }}
              className="hidden text-xs opacity-70 lg:block"
            />
            <YAxis domain={[yDomainMin(), yDomainMax()]} tick={false} tickLine={false} width={8} axisLine={false} />
            {/* <YAxis domain={[-0.5, 0.5]} tick={false} tickLine={false} width={8} axisLine={false} yAxisId="irr" /> */}
            {/* <Line
              type="monotone"
              dataKey="irr"
              stroke="#02CCFE"
              strokeWidth={2}
              name="IRR"
              activeDot={{ r: 5 }}
              yAxisId={'irr'}
              isAnimationActive={false}
            /> */}
            <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
            <Line
              type="monotone"
              dataKey="totalCommits"
              stroke="#06B823"
              strokeWidth={2}
              name="Total Committed"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="totalAssets"
              stroke="#301934"
              strokeWidth={2}
              name="Total AUM"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="totalInvestments"
              stroke="#FFA70B"
              strokeWidth={2}
              name="Total Invested"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="totalCarry"
              stroke="#FFFF00"
              strokeWidth={2}
              name="Total Carry"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />

            <Line
              type="monotone"
              dataKey="totalManagementFees"
              stroke="#000"
              strokeWidth={2}
              name="Total Management Fees"
              activeDot={{ r: 5 }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className="ml-2 lg:mt-2">
          <AdminGraphFooter
            totalCommits={activeGraphData.totalCommits}
            totalAssets={activeGraphData.totalAssets}
            totalInvestments={activeGraphData.totalInvestments}
            totalCarry={activeGraphData.totalCarry}
            totalManagementFees={activeGraphData.totalManagementFees}
          />
        </div>
        <div className="h-4" />
        <div className="mb-12 h-[1px] w-full bg-black opacity-10" />
        {Object.entries(activeGraphData.funds)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([name, fund]) => (
            <div key={name} className="my-4 origin-left scale-[80%]	">
              <div className="mb-2 text-lg font-medium">Fund {name}</div>
              <AdminGraphFooter
                totalCommits={fund.totalCommits}
                totalAssets={fund.totalAssets}
                totalInvestments={fund.totalInvestments}
                totalCarry={fund.totalCarry}
                totalManagementFees={fund.totalManagementFees}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
const LegendItems = [
  {
    color: '#FFA70B',
    label: 'Invested',
  },

  {
    color: '#301934',
    label: 'AUM',
  },
  {
    color: '#06B823',
    label: 'Committed',
  },

  {
    color: '#000',
    label: 'Management Fees',
  },
  {
    color: '#FFFF00',
    label: 'Carry',
  },
]

function Legend() {
  return (
    <div className="flex flex-col gap-2">
      {LegendItems.map((item) => (
        <LegendItem color={item.color} label={item.label} key={item.color} />
      ))}
    </div>
  )
}

function LegendItem(props: { color: string; label: string }) {
  return (
    <div className="flex items-center gap-2">
      <div className="h-4 w-4 rounded-full" style={{ backgroundColor: props.color }} />
      <div>{props.label}</div>
    </div>
  )
}
