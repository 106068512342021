import { Select } from '@radix-ui/themes'
import { TableColumnProps } from 'components/Table'
import Disclaimer from 'pages/portfolio/Disclaimer'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { Table } from '../../components/Table'

const fundById = {
  1: [
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 344530,
      investmentAmount: 249850,
      investedAt: '2023-06-02',
      entryPPS: 950,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 1034900,
      investmentAmount: 750500,
      investedAt: '2023-06-02',
      entryPPS: 950,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 1205156,
      investmentAmount: 650006.72,
      investedAt: '2023-06-04',
      entryPPS: 16.72,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Discord',
      logoUrl: 'https://storage.googleapis.com/plural_1/discord.svg',
      grossValue: 167552,
      investmentAmount: 150082.24,
      investedAt: '2023-06-04',
      entryPPS: 243.64,
      markPPS: 272,
      latestRoundPPS: 87.25,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Airtable',
      logoUrl: 'https://storage.googleapis.com/plural_1/airtable.png',
      grossValue: 59964,
      investmentAmount: 50022.6,
      investedAt: '2023-06-05',
      entryPPS: 63.4,
      markPPS: 77,
      latestRoundPPS: 187.28,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Gusto',
      logoUrl: 'https://storage.googleapis.com/plural_1/gusto.png',
      grossValue: 98848.1,
      investmentAmount: 79838.85,
      investedAt: '2023-06-07',
      entryPPS: 13.65,
      markPPS: 17.1,
      latestRoundPPS: 0.23,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Brex',
      logoUrl: 'https://storage.googleapis.com/plural_1/brex.png',
      grossValue: 398231,
      investmentAmount: 576896.8,
      investedAt: '2023-06-07',
      entryPPS: 13.4,
      markPPS: 9.3,
      latestRoundPPS: 0.11,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Gusto',
      logoUrl: 'https://storage.googleapis.com/plural_1/gusto.png',
      grossValue: 309540.4,
      investmentAmount: 250013.4,
      investedAt: '2023-06-07',
      entryPPS: 13.65,
      markPPS: 17.1,
      latestRoundPPS: 0.23,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Gusto',
      logoUrl: 'https://storage.googleapis.com/plural_1/gusto.png',
      grossValue: 341227.9,
      investmentAmount: 275607.15,
      investedAt: '2023-06-07',
      entryPPS: 13.65,
      markPPS: 17.1,
      latestRoundPPS: 0.23,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Brex',
      logoUrl: 'https://storage.googleapis.com/plural_1/brex.png',
      grossValue: 70485,
      investmentAmount: 102108,
      investedAt: '2023-06-07',
      entryPPS: 13.4,
      markPPS: 9.3,
      latestRoundPPS: 0.11,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Mercury',
      logoUrl: 'https://storage.googleapis.com/plural_1/mercury.png',
      grossValue: 511087.5,
      investmentAmount: 421260,
      investedAt: '2023-06-10',
      entryPPS: 68,
      markPPS: 83,
      latestRoundPPS: 1.37,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Rippling',
      logoUrl: 'https://storage.googleapis.com/plural_1/rippling.png',
      grossValue: 101424,
      investmentAmount: 50014.71,
      investedAt: '2023-06-10',
      entryPPS: 23.669999999999998,
      markPPS: 48.5,
      latestRoundPPS: 25.5,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Discord',
      logoUrl: 'https://storage.googleapis.com/plural_1/discord.svg',
      grossValue: 167552,
      investmentAmount: 150082.24,
      investedAt: '2023-06-13',
      entryPPS: 243.64,
      markPPS: 272,
      latestRoundPPS: 87.25,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Checkr',
      logoUrl: 'https://storage.googleapis.com/plural_1/checkr.svg',
      grossValue: 589264.3,
      investmentAmount: 499999.51,
      investedAt: '2023-06-29',
      entryPPS: 8.57,
      markPPS: 10.25,
      latestRoundPPS: 18,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 370822,
      investmentAmount: 200004.64,
      investedAt: '2023-07-05',
      entryPPS: 16.720000000000002,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Carta',
      logoUrl: 'https://storage.googleapis.com/plural_1/carta.png',
      grossValue: 56100,
      investmentAmount: 56550,
      investedAt: '2023-08-06',
      entryPPS: 18.85,
      markPPS: 18.8,
      latestRoundPPS: 40.3,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 92721,
      investmentAmount: 50009.52,
      investedAt: '2023-08-11',
      entryPPS: 16.72,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 180885,
      investmentAmount: 50005.95,
      investedAt: '2023-08-11',
      entryPPS: 8.57,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Fund I',
      logoUrl: null,
      grossValue: 310140.2,
      investmentAmount: 250000,
      investedAt: '2024-01-04',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Fund II',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 165000,
      investedAt: '2024-12-31',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Stripe',
      logoUrl: 'https://storage.googleapis.com/plural_1/stripe.png',
      grossValue: 1079460.77,
      investmentAmount: 800000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 35,
      latestRoundPPS: 30,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Headway',
      logoUrl: null,
      grossValue: 1752753.52,
      investmentAmount: 750000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 14.13,
      latestRoundPPS: 12.01,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Relativity Space',
      logoUrl: 'https://storage.googleapis.com/plural_1/relativity-space.png',
      grossValue: 167655.97,
      investmentAmount: 105000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 22,
      latestRoundPPS: 22.67,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Kajabi',
      logoUrl: null,
      grossValue: 654504.65,
      investmentAmount: 500000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 6.85,
      latestRoundPPS: 12.8,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Flexport',
      logoUrl: 'https://storage.googleapis.com/plural_1/flexport.png',
      grossValue: 1234215.62,
      investmentAmount: 1000000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 4.72,
      latestRoundPPS: null,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ro',
      logoUrl: 'https://storage.googleapis.com/plural_1/ro.png',
      grossValue: 369774.47,
      investmentAmount: 250000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 6.5,
      latestRoundPPS: 3.26,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Melio',
      logoUrl: 'https://storage.googleapis.com/plural_1/melio.png',
      grossValue: 608882.97,
      investmentAmount: 400000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 0.47,
      latestRoundPPS: 1.04,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Retool',
      logoUrl: 'https://storage.googleapis.com/plural_1/retool.svg',
      grossValue: 436292.49,
      investmentAmount: 300000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 1.71,
      latestRoundPPS: 10.4,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Deel',
      logoUrl: 'https://storage.googleapis.com/plural_1/deel.png',
      grossValue: 372967.9,
      investmentAmount: 200000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 28.4,
      latestRoundPPS: 30.63,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: 1213477.41,
      investmentAmount: 850000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Brex',
      logoUrl: 'https://storage.googleapis.com/plural_1/brex.png',
      grossValue: 144439.22,
      investmentAmount: 100000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 9.3,
      latestRoundPPS: 0.11,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Zapier',
      logoUrl: 'https://storage.googleapis.com/plural_1/zapier.svg',
      grossValue: 65536.68,
      investmentAmount: 50000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 84,
      latestRoundPPS: null,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Miro',
      logoUrl: 'https://storage.googleapis.com/plural_1/miro.png',
      grossValue: 286902.43,
      investmentAmount: 200000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 14.95,
      latestRoundPPS: 35.92,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Betterment',
      logoUrl: 'https://storage.googleapis.com/plural_1/betterment.png',
      grossValue: 981756.98,
      investmentAmount: 750000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 9.25,
      latestRoundPPS: 12.56,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Anduril',
      logoUrl: null,
      grossValue: 534237.94,
      investmentAmount: 250000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 33,
      latestRoundPPS: 21.73,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ro',
      logoUrl: 'https://storage.googleapis.com/plural_1/ro.png',
      grossValue: 295819.58,
      investmentAmount: 200000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 6.5,
      latestRoundPPS: 3.26,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Kajabi',
      logoUrl: null,
      grossValue: 327252.33,
      investmentAmount: 250000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 6.85,
      latestRoundPPS: 12.8,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Stripe',
      logoUrl: 'https://storage.googleapis.com/plural_1/stripe.png',
      grossValue: 269865.19,
      investmentAmount: 200000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 35,
      latestRoundPPS: 30,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Fivetran',
      logoUrl: 'https://storage.googleapis.com/plural_1/fivetran.png',
      grossValue: 1068838.62,
      investmentAmount: 750000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 9.04,
      latestRoundPPS: 12.08,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Komodo',
      logoUrl: 'https://storage.googleapis.com/plural_1/komodo.svg',
      grossValue: 532495.28,
      investmentAmount: 400000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 22.98,
      latestRoundPPS: 22.48,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'BetterUp',
      logoUrl: 'https://storage.googleapis.com/plural_1/betterup.png',
      grossValue: 637875,
      investmentAmount: 495000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 2.73,
      latestRoundPPS: 0.04,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Plaid',
      logoUrl: 'https://storage.googleapis.com/plural_1/plaid.png',
      grossValue: 923400,
      investmentAmount: 1000000,
      investedAt: '2023-06-10',
      entryPPS: null,
      markPPS: 240,
      latestRoundPPS: 582.15,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 1447553,
      investedAt: '2025-01-08',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
  ],
  2: [
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 908112,
      investmentAmount: 525000,
      investedAt: '2024-08-16',
      entryPPS: 9.249960357312755,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 1228780,
      investmentAmount: 1000000,
      investedAt: '2024-09-02',
      entryPPS: 1066.0980810234541,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Fund Interest',
    },
    {
      assetName: 'Fund I',
      logoUrl: null,
      grossValue: 1222080.4,
      investmentAmount: 1174624,
      investedAt: '2024-10-01',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: -300579.05,
      investmentAmount: 250000,
      investedAt: '2024-09-01',
      entryPPS: 1.08,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 250000,
      investedAt: '2025-01-28',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Fund II',
      logoUrl: null,
      grossValue: 286597.67,
      investmentAmount: 250000,
      investedAt: '2024-07-19',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: 822395.15,
      investmentAmount: 800000,
      investedAt: '2024-11-04',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Ro',
      logoUrl: 'https://storage.googleapis.com/plural_1/ro.png',
      grossValue: 422275.72,
      investmentAmount: 300000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 6.5,
      latestRoundPPS: 3.26,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Gusto',
      logoUrl: 'https://storage.googleapis.com/plural_1/gusto.png',
      grossValue: 317981.54,
      investmentAmount: 250000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 17.1,
      latestRoundPPS: 0.23,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Checkr',
      logoUrl: 'https://storage.googleapis.com/plural_1/checkr.svg',
      grossValue: 835732.99,
      investmentAmount: 800000,
      investedAt: '2024-10-01',
      entryPPS: null,
      markPPS: 10.25,
      latestRoundPPS: 18,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Zapier',
      logoUrl: 'https://storage.googleapis.com/plural_1/zapier.svg',
      grossValue: 63363.72,
      investmentAmount: 50000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 84,
      latestRoundPPS: null,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 576218.02,
      investmentAmount: 550000,
      investedAt: '2024-09-28',
      entryPPS: null,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Flexport',
      logoUrl: 'https://storage.googleapis.com/plural_1/flexport.png',
      grossValue: 633418.36,
      investmentAmount: 500000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 4.72,
      latestRoundPPS: null,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'SpaceX',
      logoUrl: 'https://storage.googleapis.com/plural_1/space-x.png',
      grossValue: 75248.77,
      investmentAmount: 50000,
      investedAt: '2023-11-30',
      entryPPS: null,
      markPPS: 205,
      latestRoundPPS: 112,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Fivetran',
      logoUrl: 'https://storage.googleapis.com/plural_1/fivetran.png',
      grossValue: 68211.95,
      investmentAmount: 50000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 9.04,
      latestRoundPPS: 12.08,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Gusto',
      logoUrl: 'https://storage.googleapis.com/plural_1/gusto.png',
      grossValue: 635963.08,
      investmentAmount: 500000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 17.1,
      latestRoundPPS: 0.23,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Yipit',
      logoUrl: 'https://storage.googleapis.com/plural_1/yipit.png',
      grossValue: 190025.51,
      investmentAmount: 150000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 44.1,
      latestRoundPPS: 93.24,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Anduril',
      logoUrl: null,
      grossValue: 583930.52,
      investmentAmount: 300000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 33,
      latestRoundPPS: 21.73,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Stripe',
      logoUrl: 'https://storage.googleapis.com/plural_1/stripe.png',
      grossValue: 1560789.2,
      investmentAmount: 1200000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 35,
      latestRoundPPS: 30,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Checkr',
      logoUrl: 'https://storage.googleapis.com/plural_1/checkr.svg',
      grossValue: 667866.88,
      investmentAmount: 500000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 10.25,
      latestRoundPPS: 18,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Pendo',
      logoUrl: 'https://storage.googleapis.com/plural_1/pendo.png',
      grossValue: 192198.68,
      investmentAmount: 150000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 6.26,
      latestRoundPPS: 12.13,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Headway',
      logoUrl: null,
      grossValue: 1578138.2,
      investmentAmount: 750000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 14.13,
      latestRoundPPS: 12.01,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: 478076.74,
      investmentAmount: 350000,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 552013.9,
      investmentAmount: 450000,
      investedAt: '2024-02-26',
      entryPPS: null,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 415377.52,
      investmentAmount: 357200,
      investedAt: '2024-03-02',
      entryPPS: null,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 669374.41,
      investmentAmount: 500000,
      investedAt: '2024-03-13',
      entryPPS: null,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 115952.68,
      investmentAmount: 89300,
      investedAt: '2023-08-15',
      entryPPS: null,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Checkr',
      logoUrl: 'https://storage.googleapis.com/plural_1/checkr.svg',
      grossValue: 589825.95,
      investmentAmount: 500000,
      investedAt: '2024-03-05',
      entryPPS: null,
      markPPS: 10.25,
      latestRoundPPS: 18,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Anduril',
      logoUrl: null,
      grossValue: 693684.83,
      investmentAmount: 500000,
      investedAt: '2024-02-21',
      entryPPS: 18.75,
      markPPS: 33,
      latestRoundPPS: 21.73,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 644570.07,
      investmentAmount: 500000,
      investedAt: '2024-03-18',
      entryPPS: 20,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 65885.81,
      investmentAmount: 50000,
      investedAt: '2024-04-24',
      entryPPS: 20,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Deel',
      logoUrl: 'https://storage.googleapis.com/plural_1/deel.png',
      grossValue: 126039.51,
      investmentAmount: 90618,
      investedAt: '2024-03-13',
      entryPPS: 16,
      markPPS: 28.4,
      latestRoundPPS: 30.63,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'OpenAI',
      logoUrl: 'https://storage.googleapis.com/plural_1/openai.svg',
      grossValue: 649282.33,
      investmentAmount: 500000,
      investedAt: '2024-04-17',
      entryPPS: 150,
      markPPS: 240,
      latestRoundPPS: 67,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Arctic Wolf',
      logoUrl: null,
      grossValue: 281200.18,
      investmentAmount: 250000,
      investedAt: '2024-04-17',
      entryPPS: 7.1,
      markPPS: 9,
      latestRoundPPS: 8.24,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Discord',
      logoUrl: 'https://storage.googleapis.com/plural_1/discord.svg',
      grossValue: 392064.94,
      investmentAmount: 350000,
      investedAt: '2024-04-17',
      entryPPS: 232,
      markPPS: 272,
      latestRoundPPS: 87.25,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Revolut',
      logoUrl: null,
      grossValue: 1202716.64,
      investmentAmount: 500000,
      investedAt: '2023-08-15',
      entryPPS: 262,
      markPPS: 831,
      latestRoundPPS: 610.59,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'DailyPay',
      logoUrl: null,
      grossValue: 31661.57,
      investmentAmount: 25000,
      investedAt: '2023-08-15',
      entryPPS: 18,
      markPPS: 20,
      latestRoundPPS: 34.28,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Discord',
      logoUrl: 'https://storage.googleapis.com/plural_1/discord.svg',
      grossValue: 330905.98,
      investmentAmount: 300000,
      investedAt: '2024-05-18',
      entryPPS: 230,
      markPPS: 272,
      latestRoundPPS: 87.25,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 325510.72,
      investmentAmount: 200000,
      investedAt: '2024-05-21',
      entryPPS: 8.5,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 148562.23,
      investmentAmount: 100000,
      investedAt: '2023-08-15',
      entryPPS: 8.5,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Benchling',
      logoUrl: null,
      grossValue: 572340.38,
      investmentAmount: 500000,
      investedAt: '2024-05-18',
      entryPPS: 10,
      markPPS: 13.2,
      latestRoundPPS: 32.7,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Benchling',
      logoUrl: null,
      grossValue: 572340.38,
      investmentAmount: 500000,
      investedAt: '2024-05-18',
      entryPPS: 10,
      markPPS: 13.2,
      latestRoundPPS: 32.7,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Discord',
      logoUrl: 'https://storage.googleapis.com/plural_1/discord.svg',
      grossValue: 326647.78,
      investmentAmount: 300000,
      investedAt: '2024-06-13',
      entryPPS: 230,
      markPPS: 272,
      latestRoundPPS: 87.25,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: 1157238.89,
      investmentAmount: 1000000,
      investedAt: '2024-07-05',
      entryPPS: 1.08,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: 1163736.66,
      investmentAmount: 1000000,
      investedAt: '2024-07-15',
      entryPPS: 1.08,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Deel',
      logoUrl: 'https://storage.googleapis.com/plural_1/deel.png',
      grossValue: 590768.13,
      investmentAmount: 500000,
      investedAt: '2024-08-09',
      entryPPS: 21,
      markPPS: 28.4,
      latestRoundPPS: 30.63,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ramp',
      logoUrl: 'https://storage.googleapis.com/plural_1/ramp.png',
      grossValue: 220548.06,
      investmentAmount: 200000,
      investedAt: '2024-08-11',
      entryPPS: 26,
      markPPS: 35,
      latestRoundPPS: 26,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Ro',
      logoUrl: 'https://storage.googleapis.com/plural_1/ro.png',
      grossValue: 498761.73,
      investmentAmount: 400000,
      investedAt: '2024-08-08',
      entryPPS: 4.6,
      markPPS: 6.5,
      latestRoundPPS: 3.26,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Human Interest',
      logoUrl: null,
      grossValue: 131593.99,
      investmentAmount: 127666,
      investedAt: '2024-10-01',
      entryPPS: 8.08,
      markPPS: 8.53,
      latestRoundPPS: 8.08,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'EvenUp',
      logoUrl: null,
      grossValue: 161392.02,
      investmentAmount: 150000,
      investedAt: '2024-10-01',
      entryPPS: 20,
      markPPS: 22.7,
      latestRoundPPS: 13.59,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Human Interest',
      logoUrl: null,
      grossValue: 23021.17,
      investmentAmount: 22334,
      investedAt: '2024-10-01',
      entryPPS: 8.08,
      markPPS: 8.53,
      latestRoundPPS: 8.08,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'OpenAI',
      logoUrl: 'https://storage.googleapis.com/plural_1/openai.svg',
      grossValue: 0,
      investmentAmount: 250000,
      investedAt: '2025-01-15',
      entryPPS: 210,
      markPPS: 240,
      latestRoundPPS: 67,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Snyk',
      logoUrl: null,
      grossValue: 856528.3,
      investmentAmount: 750087.12,
      investedAt: '2024-04-29',
      entryPPS: 6.42,
      markPPS: 7.4,
      latestRoundPPS: null,
      positionType: 'Lookback V3',
    },
  ],
  3: [
    {
      assetName: 'Scale',
      logoUrl: 'https://storage.googleapis.com/plural_1/scale.png',
      grossValue: 0,
      investmentAmount: 1249317,
      investedAt: '2025-01-08',
      entryPPS: 14,
      markPPS: 16.1,
      latestRoundPPS: 14.55,
      positionType: 'Lookback V3',
    },
    {
      assetName: 'Canva',
      logoUrl: 'https://storage.googleapis.com/plural_1/canva.png',
      grossValue: 0,
      investmentAmount: 878075,
      investedAt: '2025-01-08',
      entryPPS: 1250,
      markPPS: 1325,
      latestRoundPPS: 1066.63,
      positionType: 'Fixed-Priced Forward',
    },
    {
      assetName: 'Fund II',
      logoUrl: null,
      grossValue: 4012367.42,
      investmentAmount: 3500000,
      investedAt: '2024-07-19',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Checkr',
      logoUrl: 'https://storage.googleapis.com/plural_1/checkr.svg',
      grossValue: 815667.02,
      investmentAmount: 800000,
      investedAt: '2024-11-04',
      entryPPS: null,
      markPPS: 10.25,
      latestRoundPPS: 18,
      positionType: 'Lookback V1',
    },
    {
      assetName: 'Ironclad',
      logoUrl: 'https://storage.googleapis.com/plural_1/ironclad.png',
      grossValue: 300579.05,
      investmentAmount: 250000,
      investedAt: '2024-09-01',
      entryPPS: 1.08,
      markPPS: 1.42,
      latestRoundPPS: 2.28,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 250000,
      investedAt: '2025-01-28',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Fund II',
      logoUrl: null,
      grossValue: -286597.67,
      investmentAmount: 250000,
      investedAt: '2024-07-19',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: -822395.15,
      investmentAmount: 800000,
      investedAt: '2024-11-04',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
    {
      assetName: 'OpenAI',
      logoUrl: 'https://storage.googleapis.com/plural_1/openai.svg',
      grossValue: 0,
      investmentAmount: 250000,
      investedAt: '2025-01-15',
      entryPPS: 210,
      markPPS: 240,
      latestRoundPPS: 67,
      positionType: 'Lookback V2',
    },
    {
      assetName: 'Anduril',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 500000,
      investedAt: '2025-01-15',
      entryPPS: 35,
      markPPS: 33,
      latestRoundPPS: 21.73,
      positionType: 'Lookback V3',
    },
    {
      assetName: 'SpaceX',
      logoUrl: 'https://storage.googleapis.com/plural_1/space-x.png',
      grossValue: 0,
      investmentAmount: 1000000,
      investedAt: '2025-01-08',
      entryPPS: 185,
      markPPS: 205,
      latestRoundPPS: 112,
      positionType: 'Lookback V3',
    },
    {
      assetName: 'Fund III',
      logoUrl: null,
      grossValue: 0,
      investmentAmount: 1447553,
      investedAt: '2025-01-08',
      entryPPS: null,
      markPPS: null,
      latestRoundPPS: null,
      positionType: 'Par-priced forward',
    },
  ],
}

function formatDollars(amount: number | null, includeCents: boolean = false) {
  if (!amount) return null
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: includeCents ? 2 : 0,
    maximumFractionDigits: includeCents ? 2 : 0,
  })
}

type CompanyPositionRow = (typeof fundById)[keyof typeof fundById][number]

export default function TempPortfolio() {
  const { user } = usePluralAuth()
  if (!user) throw new Error('Please log in') // TODO: client-side auth?
  let params = useParams()
  let fundId = params.fundId ? parseInt(params.fundId) : 1

  let data = fundById[fundId as keyof typeof fundById]
    .map((e) => ({ ...e }))
    .filter((e) => new Date(e.investedAt).getTime() < new Date('2024-12-31').getTime() && e.grossValue >= 0)
  // Group same assets if they have the same type, entry date, and entry point
  for (let i = 0; i < data.length; ) {
    const prev = data
      .slice(0, i)
      .find(
        (e) =>
          e.assetName === data[i].assetName &&
          e.entryPPS === data[i].entryPPS &&
          e.positionType === data[i].positionType &&
          e.investedAt === data[i].investedAt,
      )
    if (prev) {
      prev.grossValue += data[i].grossValue
      prev.investmentAmount += data[i].investmentAmount
      data.splice(i, 1)
    } else i++
  }

  data.sort((a, b) => b.grossValue - a.grossValue)

  const fundNameById: { [id: number]: string } = {
    1: 'Plural Capital Fund I, LP',
    2: 'Plural Capital Fund II, LP',
    3: 'Plural Capital Fund III, LP',
  }

  const fundIdsInvolved = [
    ...new Set(data.filter((e) => e.assetName.startsWith('Fund')).map((e) => e.assetName.replace(/[^I]/g, '').length)),
  ]
  data = data.filter((e) => !e.assetName.startsWith('Fund'))

  let disclaimer = ''
  if (fundIdsInvolved.length) {
    const res = fundIdsInvolved.map(
      (id) =>
        `${[...new Set(fundById[id as keyof typeof fundById].filter((e) => !e.assetName.startsWith('Fund')).map((e) => e.assetName))].join(', ')} of ${fundNameById[id]}`,
    )
    disclaimer = `Portfolio also includes ${res.join(', ')} (warehoused for cross-diversification purposes).`
  }
  console.log(fundIdsInvolved)

  const columns: TableColumnProps<CompanyPositionRow>[] = [
    {
      header: 'Company',
      render: (row: CompanyPositionRow) => {
        const companyName = row.assetName
        return (
          <div className="align-center flex flex-row  gap-2 pr-9">
            {row.logoUrl ? (
              <img src={row.logoUrl} alt="logo" className="border-gray-80 h-8 w-8 rounded-full border border-solid" />
            ) : (
              <div className="border-gray-80 flex h-8 w-8 items-center justify-center rounded-full border border-solid">
                {companyName[0]}
              </div>
            )}
            <div className="flex flex-row items-center font-normal">{companyName}</div>
          </div>
        )
      },
    },
    {
      header: 'Investment Amount',
      render: (row: CompanyPositionRow) => formatDollars(row.investmentAmount),
    },
    {
      header: 'Current Value',
      render: (row: CompanyPositionRow) => formatDollars(row.grossValue),
    },
    {
      header: 'Investment Date',
      render: (row: CompanyPositionRow) => row.investedAt,
    },
    {
      header: 'Entry PPS',
      render: (row: CompanyPositionRow) => formatDollars(row.entryPPS, true),
    },
    {
      header: 'Mark PPS',
      render: (row: CompanyPositionRow) => formatDollars(row.markPPS, true),
    },
    {
      header: 'Latest Round PPS',
      render: (row: CompanyPositionRow) => formatDollars(row.latestRoundPPS, true),
    },
  ]

  return (
    <div className="mb-24 flex w-full flex-col items-center justify-start pl-4 pr-4">
      <div className={`mt-12 flex w-full max-w-page-max flex-col items-center justify-center `}>
        <div className="justify-apart mb-6 flex w-full items-center">
          <div className="items-left flex w-full py-2 pt-4 text-4xl font-semibold">{fundNameById[fundId]}</div>
          <div>
            <FundSwitch selectedFundId={fundId} />
          </div>
        </div>
        <div className="items-left flex w-full py-3">
          <div className="flex w-full gap-6 space-x-6 pt-2">
            <div className="flex flex-col gap-2">
              <div className="opacity-80">AUM</div>
              <div className="text-2xl font-medium">
                {
                  {
                    1: '$22,079,361.6',
                    2: '$25,740,705.79',
                    3: '$4,019,620.67',
                  }[fundId]
                }
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="opacity-80">Marked gross IRR</div>
              <div className="text-2xl font-medium">
                {
                  {
                    1: '26.1%',
                    2: '44.8%',
                    3: '45.4%',
                  }[fundId]
                }
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 w-full">
          <div className="text-xl">Portfolio</div>
        </div>
        <div className="mt-7 max-w-full">
          <div className="flex w-full flex-col justify-start">
            <Table<CompanyPositionRow>
              maxHeight="max-h-160"
              stickyHeader
              stickyColumn
              rowData={data}
              defaultRowCount={100}
              columns={columns}
            />
            {disclaimer && <div className="mt-4 w-full text-sm opacity-50">* {disclaimer}</div>}
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-page-max flex-col justify-start">
        <div className="mt-72 flex flex-col gap-2 pb-16">
          <Disclaimer shouldApplyMarginTop={false} />
        </div>
      </div>
    </div>
  )
}

export function FundSwitch(props: { selectedFundId: number }) {
  let navigate = useNavigate()
  return (
    <Select.Root
      value={props.selectedFundId.toString()}
      onValueChange={(newValue) => {
        navigate(`/fund/${newValue}`)
      }}>
      <Select.Trigger></Select.Trigger>
      <Select.Content>
        <Select.Item value={'1'}>Fund I</Select.Item>
        <Select.Item value={'2'}>Fund II</Select.Item>
        <Select.Item value={'3'}>Fund III</Select.Item>
      </Select.Content>
    </Select.Root>
  )
}
