import { IonItem, IonSpinner } from '@ionic/react'
import { AdminPageContainer } from 'admin/AdminSideNav'
import Cohere from "cohere-js"
import LogRocket from 'logrocket'
import AdminDashboardPage from 'pages/admin/AdminDashboardPage'
import AdminFundCommits from 'pages/admin/AdminFundCommits'
import AdminMarksPage from 'pages/admin/AdminMarksPage'
import AdminUsers from 'pages/admin/AdminUsers'
import { EntityAdminSheet } from 'pages/admin/EntityAdminSheet'
import AdminSheetsPage from 'pages/adminSheet'
import CompanyProfile from 'pages/company/CompanyProfile'
import PrivateEquityFundFileUploadPage from 'pages/PrivateEquityFundFileUploadPage'
import NavigationUIProvider from 'providers/NavigationUIProvider'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ArrayElement } from 'safety'
import CompanyPage from 'several/pages/CompanyPage'
import ListsPage from 'several/pages/ListsPage'
import NewsPage from 'several/pages/NewsPage'
import SeveralHomePage from 'several/pages/SeveralHomePage'
import { AuthStatus } from 'utils/authStatus'
import { RouterOutputs, trpc } from 'utils/trpc'
import Announcements from './Announcements'
import CaptureInterestWithData from './CaptureInterestWithData'
import Disclaimers from './Disclaimers'
import './global.css'
import './instrument'
import LandingPage from './LandingPage'
import Allowlist from './pages/allowlist/Allowlist'
import AuthStatusPage from './pages/AuthStatusPage'
import BookDemo from './pages/BookDemo'
import CashRecipientOutcomeSimulator from './pages/cashSimulator/CashOutcomeSimulator'
import AllCompanies from './pages/company/AllCompanies'
import CompanyEditor from './pages/company/CompanyEditor'
import DealSensitivity from './pages/dealSensitivity/DealSensitivity'
import FundSensitivity from './pages/equityFundSimulation/EquityFundSimulation'
import FundDetailPage from './pages/FundDetailPage'
import FundFileUploadPage from './pages/FundFileUploadPage'
import LogIn from './pages/LogIn'
import LPInterestSensitivity from './pages/LPFundInterestSimulation/LPInterestFundSimulation'
import NavigationLayout from './pages/NavigationLayout'
import NoAllowlist from './pages/NoAllowlist'
import Onboarding from './pages/Onboarding'
import PageModalLayout from './pages/PageModalLayout'
import RequireAuthStateToRender from './pages/portfolio/RequireAuthStateToRender'
import TempPortfolio from './pages/portfolio/TempPortfolio'
import TeamPage from './pages/TeamPage'
import AcceptableUsePolicy from './pages/terms/AcceptableUsePolicy'
import AiTermsOfUse from './pages/terms/AiTermsOfUse'
import PrivacyPolicy from './pages/terms/PrivacyPolicy'
import Subprocessors from './pages/terms/Subprocessors'
import TermsOfUse from './pages/terms/TermsOfUse'
import ValuationCalculator from './pages/valuation-calculator/ValuationCalculator'


export default function App() {
  const { authStatus, user } = usePluralAuth();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Cohere.init("stQHwkulwyaFiFTJVLTxySYR");
      LogRocket.init('pf5off/plural');

      if (authStatus === AuthStatus.Allowed && user) {
        Cohere.identify(user.id.toString(), {
          email: user.email
        });
        LogRocket.identify(user.id.toString(), {
          email: user.email
        });
      }
    }
  }, [authStatus, user]);

  return (
    <div className="font-saans">
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <NavigationLayout>
                <LandingPage />
              </NavigationLayout>
            </div>
          }
        />
        <Route
          path="/tx-calculator"
          element={
            <Routes>
              <Route index element={<Navigate to="/cash-sim" replace />} />
            </Routes>
          }
        />
        <Route
          path="/cash-sim"
          element={
            <Routes>
              <Route index element={<Navigate to="/offer-calculator" replace />} />
            </Routes>
          }
        />
        <Route
          path="/fund-sensitivity"
          element={
            <Routes>
              <Route index element={<Navigate to="/equity-fund-sim" replace />} />
            </Routes>
          }
        />
        <Route
          path="/disclaimers"
          element={
            <NavigationLayout>
              <Disclaimers />
            </NavigationLayout>
          }
        />
        <Route
          path="/demo"
          element={
            <PageModalLayout>
              <BookDemo />
            </PageModalLayout>
          }
        />
        <Route
          path="/allowlist"
          element={
            <NavigationLayout>
              <RequireAuthStateToRender>
                <Allowlist />
              </RequireAuthStateToRender>
            </NavigationLayout>
          }
        />
        <Route path="login" element={<LogIn />} />
        <Route
          path="/companies"
          element={
            <NavigationLayout>
              <AllCompanies />
            </NavigationLayout>
          }
        />

        <Route path="/company-editor" element={<Navigate to={'/company-editor/none'} />} />
        <Route
          path="/company-editor/:companyId"
          element={
            <NavigationLayout>
              <CompanyEditor />
            </NavigationLayout>
          }
        />
        <Route
          path="/offer-calculator"
          element={
            <NavigationLayout>
              <CashRecipientOutcomeSimulator />
            </NavigationLayout>
          }
        />
        <Route
          path="/get-started"
          element={
            <PageModalLayout>
              <CaptureInterestWithData />
            </PageModalLayout>
          }
        />
        <Route
          path="/calculator"
          element={
            <NavigationLayout>
              <ValuationCalculator />
            </NavigationLayout>
          }
        />
        <Route
          path="/announcements"
          element={
            <NavigationLayout>
              <Announcements />
            </NavigationLayout>
          }
        />
        <Route
          path="/team"
          element={
            <NavigationLayout>
              <TeamPage />
            </NavigationLayout>
          }
        />
        <Route
          path="/fund-upload"
          element={
            <NavigationLayout>
              <FundFileUploadPage />
            </NavigationLayout>
          }
        />
        <Route
          path="/pe-upload"
          element={
            <NavigationLayout>
              <PrivateEquityFundFileUploadPage />
            </NavigationLayout>
          }
        />
        <Route
          path="/terms"
          element={
            <NavigationLayout>
              <TermsOfUse />
            </NavigationLayout>
          }
        />
        <Route
          path="/terms/ai"
          element={
            <NavigationLayout>
              <AiTermsOfUse />
            </NavigationLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <NavigationLayout>
              <PrivacyPolicy />
            </NavigationLayout>
          }
        />
        <Route
          path="/subprocessors"
          element={
            <NavigationLayout>
              <Subprocessors />
            </NavigationLayout>
          }
        />
        <Route
          path="/aup"
          element={
            <NavigationLayout>
              <AcceptableUsePolicy />
            </NavigationLayout>
          }
        />
        {/* <Route
          path="/multistage"
          element={
            <NavigationLayout>
              <MultistagePortfolio />
            </NavigationLayout>
          }
        /> */}

        <Route
          path="/admin/entities"
          element={
            <AdminPageContainer>
              <EntityAdminSheet />
            </AdminPageContainer>
          }
        />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route
          path="/admin/fund/:fundId/commits"
          element={
            // <NavigationLayout>
            <AdminFundCommits />
            // </NavigationLayout>
          }
        />
        <Route path="/admin/marks" element={<AdminMarksPage />} />
        <Route
          path="/admin/fund/:fundId/investments"
          element={
            <AdminPageContainer>
              <AdminSheetsPage />
            </AdminPageContainer>
          }
        />
        <Route path="/admin" element={<AdminDashboardPage />} />
        <Route
          path="/equity-fund-sim"
          element={
            <NavigationLayout>
              <FundSensitivity />
            </NavigationLayout>
          }
        />
        <Route
          path="/lp-interest-fund-sim"
          element={
            <NavigationLayout>
              <LPInterestSensitivity />
            </NavigationLayout>
          }
        />
        <Route
          path="/deal-sensitivity"
          element={
            <NavigationLayout>
              <DealSensitivity />
            </NavigationLayout>
          }
        />
        <Route path="/auth-status" element={<AuthStatusPage />} />
        <Route
          path="/no-allowlist"
          element={
            <NavigationLayout>
              <RequireAuthStateToRender>
                <NoAllowlist />
              </RequireAuthStateToRender>
            </NavigationLayout>
          }
        />
        <Route
          path="/onboarding"
          element={
            <NavigationLayout>
              <RequireAuthStateToRender>
                <Onboarding />
              </RequireAuthStateToRender>
            </NavigationLayout>
          }
        />
        <Route path="/my-holdings" element={<MyHoldingsRedirect />} />
        <Route path="/portfolio" element={<Navigate to={'/portfolio/1'} />} />
        <Route
          path="/portfolio/:fundId"
          element={
            <RedirectComponent />
            // <NavigationLayout>
            //   <RequireAuthStateToRender>
            //     <Portfolio />
            //   </RequireAuthStateToRender>
            // </NavigationLayout>
          }
        />
        <Route path="/fund" element={<Navigate to={'/fund/1'} />} />
        <Route
          path="/fund/:fundId"
          element={
            <NavigationLayout>
              <RequireAuthStateToRender>
                <TempPortfolio />
              </RequireAuthStateToRender>
            </NavigationLayout>
          }
        />
        <Route path="/" element={<NavigationUIProvider />}>
          {/* Dashboard Routes */}
          <Route path="/dashboard" index element={<SeveralHomePage defaultEntity="company" />} />
          <Route
            path="/dashboard/sheets/company/:sheetId"
            index
            element={<SeveralHomePage defaultEntity="company" />}
          />
          <Route path="/dashboard/sheets/company" index element={<SeveralHomePage defaultEntity="company" />} />
          <Route
            path="/dashboard/sheets/funding/:sheetId"
            index
            element={<SeveralHomePage defaultEntity="funding" />}
          />
          <Route path="/dashboard/lists" index element={<ListsPage />} />
          <Route path="/dashboard/sheets/funding" index element={<SeveralHomePage defaultEntity="funding" />} />

          <Route
            path="/dashboard/sheets/fund-snapshots"
            index
            element={<SeveralHomePage defaultEntity="fund-snapshot" />}
          />
          <Route path="/fund-snapshots/:fundId" index element={<FundDetailPage />} />
          <Route path="/fund-snapshots/upload" index element={<FundFileUploadPage />} />

          <Route path="/dashboard/sheets/holdings" index element={<SeveralHomePage defaultEntity="holdings" />} />
          <Route
            path="/dashboard/sheets/holdings/:sheetId"
            index
            element={<SeveralHomePage defaultEntity="holdings" />}
          />

          <Route path="/dashboard/sheets/indications" index element={<SeveralHomePage defaultEntity="indications" />} />
          <Route
            path="/dashboard/sheets/indications/:sheetId"
            index
            element={<SeveralHomePage defaultEntity="indications" />}
          />
          <Route path="/dashboard/news" index element={<NewsPage />} />

          <Route path="/company-profile" index element={<Navigate to={'/company-profile/none'} />} />
          <Route path="/company-profile/:companyId" index element={<CompanyProfile />} />
          <Route path="/company/:companyId" index element={<CompanyPage />} />
          <Route path="/test-company" index element={<CompanyPage />} />
        </Route>
      </Routes>
    </div>
  )
}

type RouterUser = RouterOutputs['user']['getSelfWithFundCommits']
type RouterEntity = ArrayElement<RouterUser['legalEntitiesToUser']>
type RouterFundCommit = ArrayElement<RouterEntity['legalEntity']['fundCommits']>
function MyHoldingsRedirect() {
  const { authStatus, isLoading: isAuthLoading } = usePluralAuth()
  let userQuery = trpc.user.getSelfWithFundCommits
  let user: RouterUser | undefined = userQuery.useQuery(undefined, {
    enabled: !isAuthLoading,
  })?.data

  let investmentsInFundOne =
    user?.legalEntitiesToUser.reduce((prev: number, curr: RouterEntity) => {
      return (
        prev +
        curr.legalEntity.fundCommits.reduce((prev: number, curr: RouterFundCommit) => {
          return prev + curr.fundId === 1 ? curr.cashDelta : 0
        }, 0)
      )
    }, 0) ?? 0

  let investmentsInFundTwo =
    user?.legalEntitiesToUser.reduce((prev: number, curr: RouterEntity) => {
      return (
        prev +
        curr.legalEntity.fundCommits.reduce((prev: number, curr: RouterFundCommit) => {
          return prev + curr.fundId === 2 ? curr.cashDelta : 0
        }, 0)
      )
    }, 0) ?? 0

  if (isAuthLoading || (!user && authStatus === AuthStatus.Allowed)) {
    return (
      <div className="loader flex h-[100vh] w-full flex-col items-center justify-center">
        <IonItem>
          <IonSpinner name="lines-sharp"></IonSpinner>
        </IonItem>
      </div>
    )
  }
  if (authStatus === AuthStatus.None) {
    return <Navigate to="/login" />
  }

  if (investmentsInFundOne >= investmentsInFundTwo) {
    return <Navigate to="/portfolio/1" />
  } else {
    return <Navigate to="/portfolio/2" />
  }
}



function RedirectComponent() {
  useEffect(() => {
    window.location.href = 'https://app.withplural.com';
  }, []);

  return null; // No UI needed
}